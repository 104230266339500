import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  // "MPF878RPY2",
  // "d4ee6be1a0f92868dfea1bcc1dcf5b9f",
  "HUC1VFAK3J",
  "2a03019f80f0f0ea7897642472133c4d"
);

export default searchClient;
