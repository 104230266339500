// Arrow svg used in feedback session

import React from "react";

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.706 11.2928L14.706 4.29279C14.5661 4.15298 14.388 4.05777 14.194 4.0192C14.0001 3.98064 13.799 4.00044 13.6163 4.07611C13.4336 4.15178 13.2775 4.27992 13.1676 4.44433C13.0577 4.60874 12.999 4.80204 12.999 4.99979V8.14579C10.4562 8.53048 8.07581 9.63204 6.13677 11.3213C4.19773 13.0107 2.78043 15.2177 2.05099 17.6838C1.98386 17.8859 1.98299 18.1041 2.0485 18.3067C2.11401 18.5093 2.24248 18.6857 2.4152 18.8102C2.58792 18.9347 2.79588 19.0009 3.0088 18.999C3.22172 18.9971 3.42848 18.9273 3.59899 18.7998C7.36799 15.9728 9.36299 15.9888 12.999 15.9998V18.9998C12.9989 19.1976 13.0575 19.3909 13.1674 19.5554C13.2772 19.7198 13.4333 19.848 13.616 19.9238C13.7373 19.9744 13.8675 20.0002 13.999 19.9998C14.2642 19.9997 14.5185 19.8943 14.706 19.7068L21.706 12.7068C21.8935 12.5193 21.9988 12.265 21.9988 11.9998C21.9988 11.7346 21.8935 11.4803 21.706 11.2928Z"
        fill="#FF8E31"
      />
    </svg>
  );
};

export default Arrow;
