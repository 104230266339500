import React from "react";

const Leaf = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clipPath="url(#clip0_2808_30711)">
        <path
          d="M17.2355 26.3325C17.2355 26.3325 12.3137 30.6988 7.4574 28.5857C2.60115 26.4725 0.712402 25.6869 0.712402 25.6869C0.712402 25.6869 5.46303 22.9925 6.91803 21.8507C8.37303 20.7088 18.0537 24.4788 18.108 23.9988C18.1624 23.5188 6.95553 19.6082 5.82928 17.87C4.70303 16.1319 6.09865 9.33254 5.32553 6.84691C4.5524 4.36129 4.3399 3.58254 4.3399 3.58254C4.3399 3.58254 7.18928 5.50066 10.353 5.84441C13.5168 6.18816 17.7687 11.5357 19.0674 23.0857C19.8987 19.3238 17.6712 7.09816 26.0562 3.41004C29.9887 1.12566 28.9024 0.213789 30.3343 0.00816443C31.7662 -0.197461 29.5268 3.85629 30.3062 4.66629C31.0855 5.47629 36.0855 10.1544 30.7149 15.3432C25.3443 20.5319 19.8468 23.895 19.8468 23.895C19.8468 23.895 31.7474 17.7575 35.5493 20.4375C39.3512 23.1175 41.3618 28.4275 36.0837 30.8944C30.8055 33.3613 20.8705 25.94 20.8705 25.94C20.8705 25.94 25.3912 31.5863 24.7974 38.2588C24.7974 38.2588 14.8912 38.085 18.3374 26.5394C10.7068 38.765 10.9518 40 10.9518 40L9.73865 38.2857L17.2355 26.3325Z"
          fill="#90B74B"
        />
        <path
          d="M18.338 26.54C14.8917 38.085 24.798 38.2593 24.798 38.2593C25.3911 31.5868 20.8711 25.9406 20.8711 25.9406C20.8711 25.9406 30.8061 33.3612 36.0842 30.895C41.3624 28.4287 39.3517 23.1187 35.5499 20.4381C31.748 17.7575 19.8474 23.8956 19.8474 23.8956L18.338 26.54Z"
          fill="#AAD15D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2808_30711">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Leaf;
