const data = [
  {
    _id: "648189c34fbe873afe9747d8",
    region: "ayeyarwaddy",
    list: [
      { city: "Pathein", fees: 4000, _id: "63f49073517358474843d6c3" },
      { city: "Hinthada", fees: 4000, _id: "63f4963c7ede83c89bd37f4d" },
      { city: "Athote", fees: 4000, _id: "63f4969d9bbf02e4bbdabed9" },
      { city: "Kyonepyaw", fees: 4000, _id: "63f496c09bbf02e4bbdabef2" },
      { city: "Bogale", fees: 4000, _id: "63f496cb9bbf02e4bbdabf07" },
      { city: "Ngathaingchaung", fees: 4000, _id: "63f496ea9bbf02e4bbdabf20" },
      { city: "Yay Kyi", fees: 4000, _id: "63f49a9d782ee5319712eb10" },
      { city: "Maubin", fees: 4000, _id: "63f49ade782ee5319712eb39" },
      { city: "Myaungmya", fees: 4000, _id: "63f49aeb782ee5319712eb5e" },
      { city: "Kyaiklat", fees: 4000, _id: "63f49af8782ee5319712eb87" },
      { city: "Kyaungkone", fees: 4000, _id: "63f49b35782ee5319712ebb4" },
      { city: "Eainme", fees: 4000, _id: "63f49b49782ee5319712ebe5" },
      { city: "Dedaye", fees: 4000, _id: "63f49b69782ee5319712ec1a" },
      { city: "Myanaung", fees: 4000, _id: "63f49b77782ee5319712ec53" },
      { city: "Nyaungdon", fees: 4000, _id: "63f49b80782ee5319712ec90" },
      { city: "Wakhema", fees: 4000, _id: "63f49b8c782ee5319712ecd1" },
      { _id: "64a7da0c6dcca4884e34819b", city: "Pantanaw", fees: 4000 },
      { _id: "64a7da0c6dcca4884e34819c", city: "Danuphyu", fees: 4000 },
      { _id: "64a7da0c6dcca4884e34819d", city: "Zalun", fees: 4000 },
      { _id: "64a7da0c6dcca4884e34819e", city: "Kangyidaunt", fees: 4000 },
      { _id: "64a7da0c6dcca4884e34819f", city: "Pyapon", fees: 4000 },
      { _id: "64a7da0c6dcca4884e3481a0", city: "Dar ka", fees: 4000 },
      { _id: "64a7da0c6dcca4884e3481a1", city: "Kyangin", fees: 4000 },
    ],
    createdAt: "2023-02-21T09:21:10.924Z",
    updatedAt: "2023-02-21T10:31:07.218Z",
    __v: 0,
  },
  {
    _id: "648189054fbe873afe9747d6",
    region: "bago",
    list: [
      { city: "Bago", fees: 4000, _id: "63f5c6cdeaa9289821461762" },
      { city: "Taungoo", fees: 4000, _id: "63f5c6e6eaa92898214617b8" },
      { city: "Pyay", fees: 4000, _id: "63f5c6f2eaa9289821461813" },
      {
        city: "Kaytumadi Myo Thit",
        fees: 4000,
        _id: "63f8377b012e23b5b52040c2",
      },
    ],
    createdAt: "2023-02-22T07:39:57.315Z",
    updatedAt: "2023-02-24T04:17:27.880Z",
    __v: 0,
  },
  {
    _id: "64818ba14fbe873afe9747da",
    region: "kayin",
    list: [{ city: "Hpa-an", fees: 4000, _id: "63f5c6cdeaa9289821461762" }],
    createdAt: "2023-02-22T07:39:57.315Z",
    updatedAt: "2023-02-24T04:17:27.880Z",
    __v: 0,
  },
  {
    _id: "64818bc34fbe873afe9747dc",
    region: "magway",
    list: [
      { city: "Magway", fees: 4000, _id: "63f5c6cdeaa9289821461762" },
      { city: "Minbu", fees: 4000, _id: "63f5c6e6eaa92898214617b8" },
    ],
    createdAt: "2023-02-22T07:39:57.315Z",
    updatedAt: "2023-02-24T04:17:27.880Z",
    __v: 0,
  },
  {
    _id: "63f5c6cdeaa9289821461761",
    region: "mandalay",
    list: [
      { city: "Mandalay", fees: 4000, _id: "63f5c6cdeaa9289821461762" },
      { city: "Kyaukse", fees: 4000, _id: "63f5c6e6eaa92898214617b8" },
      { city: "Bagan", fees: 4000, _id: "63f5c6f2eaa9289821461813" },
      {
        city: "Aung Myay Thar Zan",
        fees: 4000,
        _id: "63f8377b012e23b5b52040c2",
      },
      {
        city: "Chan Aye Thar Zan",
        fees: 4000,
        _id: "63f8378b012e23b5b5204125",
      },
      { city: "Mahar Aung Myay", fees: 4000, _id: "63f8379b012e23b5b520418c" },
      { city: "Chan Mya Thar Si", fees: 4000, _id: "63f837ad012e23b5b52041f7" },
      { city: "Pyi Gyi Tagon", fees: 4000, _id: "63f837d6012e23b5b5204266" },
      { city: "Amarapura", fees: 4000, _id: "63f837e5012e23b5b52042d9" },
      { city: "Meiktila", fees: 4000, _id: "63f837fb012e23b5b5204350" },
      { city: "Kyaukpadaung", fees: 4000, _id: "63f83814012e23b5b52043cb" },
      { city: "Tada-U", fees: 4000, _id: "63f83830012e23b5b520444a" },
      { city: "Ohn Chaw", fees: 4000, _id: "63f8384a012e23b5b52044cd" },
      { city: "Mogok", fees: 4000, _id: "63f83863012e23b5b5204554" },
      { city: "Pyin Oo Lwin", fees: 4000, _id: "63f83871012e23b5b52045df" },
      { city: "Myingyan", fees: 4000, _id: "63f83883012e23b5b520466e" },
      { city: "Yamethin", fees: 4000, _id: "63f8389a012e23b5b5204701" },
      { city: "Myitnge", fees: 4000, _id: "63f838ac012e23b5b5204798" },
      { city: "Pulate", fees: 4000, _id: "63f838cb012e23b5b5204833" },
      { city: "Sint Gaing", fees: 4000, _id: "63f838e0012e23b5b52048d2" },
      { city: "Wundwin", fees: 4000, _id: "63f838f6012e23b5b5204975" },
      { city: "Thel Taw", fees: 4000, _id: "63f8395a012e23b5b5204a1c" },
      { city: "Pyawbwe", fees: 4000, _id: "63f83978012e23b5b5204ac7" },
      { city: "Nyaung Oo", fees: 4000, _id: "63f8398d012e23b5b5204b76" },
      { city: "Myittha", fees: 4000, _id: "63f8399d012e23b5b5204c29" },
      { city: "Kume", fees: 4000, _id: "63f839b5012e23b5b5204ce0" },
      { city: "Mattaya", fees: 4000, _id: "63f839ce012e23b5b5204d9b" },
      { city: "Pathein Gyi", fees: 4000, _id: "63f839e5012e23b5b5204e5a" },
      {
        city: "Han Myint Mo Crossroad",
        fees: 3500,
        _id: "63f83a57012e23b5b5204f1d",
      },
    ],
    createdAt: "2023-02-22T07:39:57.315Z",
    updatedAt: "2023-02-24T04:17:27.880Z",
    __v: 0,
  },
  {
    _id: "648189754fbe873afe9747d7",
    region: "mon",
    list: [
      { city: "Mawlamyine", fees: 4000, _id: "63f5c6cdeaa9289821461762" },
      { city: "Mottama", fees: 4000, _id: "63f5c6e6eaa92898214617b8" },
      { city: "Kyaikhto", fees: 4000, _id: "63f5c6f2eaa9289821461813" },
      { city: "Thahtone", fees: 4000, _id: "63f8377b012e23b5b52040c2" },
    ],
    createdAt: "2023-02-22T07:39:57.315Z",
    updatedAt: "2023-02-24T04:17:27.880Z",
    __v: 0,
  },
  {
    _id: "6481884d4fbe873afe9747d5",
    region: "naypyidaw",
    list: [
      { city: "Naypyidaw", fees: 4000, _id: "63f5c6cdeaa9289821461762" },
      { city: "Pyinmana", fees: 4000, _id: "63f5c6e6eaa92898214617b8" },
      { _id: "64a7da0c6dcca4884e348152", city: "Lewe", fees: 4000 },
      { city: "Tatkon", fees: 4000, _id: "63f5c6f2eaa9289821461813" },
      { city: "Pyankapyay", fees: 4000, _id: "63f8377b012e23b5b52040c2" },
    ],
    createdAt: "2023-02-22T07:39:57.315Z",
    updatedAt: "2023-02-24T04:17:27.880Z",
    __v: 0,
  },
  {
    _id: "64818c1b4fbe873afe9747de",
    region: "rakhine",
    list: [{ city: "Sittwe", fees: 5500, _id: "63f5c6cdeaa9289821461762" }],
    createdAt: "2023-02-22T07:39:57.315Z",
    updatedAt: "2023-02-24T04:17:27.880Z",
    __v: 0,
  },
  {
    _id: "64818b834fbe873afe9747d9",
    region: "sagaing",
    list: [{ city: "Sagaing", fees: 4000, _id: "63f5c6cdeaa9289821461762" }],
    createdAt: "2023-02-22T07:39:57.315Z",
    updatedAt: "2023-02-24T04:17:27.880Z",
    __v: 0,
  },
  {
    _id: "64818be74fbe873afe9747dd",
    region: "shan",
    list: [
      { city: "Taunggyi", fees: 4000, _id: "63f5c6cdeaa9289821461762" },
      { city: "Lashio", fees: 4000, _id: "63f5c6e6eaa92898214617b8" },
      { city: "Ayetharyar", fees: 4000, _id: "63f5c6f2eaa9289821461813" },
    ],
    createdAt: "2023-02-22T07:39:57.315Z",
    updatedAt: "2023-02-24T04:17:27.880Z",
    __v: 0,
  },
  {
    _id: "63f48d068ca3c7db0984071e",
    region: "yangon",
    list: [
      { city: "Latha", fees: 2000, _id: "63f49073517358474843d6c3" },
      { city: "Kyauktada", fees: 2000, _id: "63f4963c7ede83c89bd37f4d" },
      { city: "Pabedan", fees: 2000, _id: "63f4969d9bbf02e4bbdabed9" },
      { city: "Lanmadaw", fees: 2000, _id: "63f496c09bbf02e4bbdabef2" },
      { city: "Botahtaung", fees: 2000, _id: "63f496cb9bbf02e4bbdabf07" },
      { city: "Pazuntaung", fees: 2000, _id: "63f496ea9bbf02e4bbdabf20" },
      {
        city: "Mingalar Taung Nyunt",
        fees: 2000,
        _id: "63f49a9d782ee5319712eb10",
      },
      { city: "Alone", fees: 2500, _id: "63f49ade782ee5319712eb39" },
      { city: "Bahan", fees: 2500, _id: "63f49aeb782ee5319712eb5e" },
      { city: "Dagon", fees: 2500, _id: "63f49af8782ee5319712eb87" },
      {
        city: "Dagon Myothit (seikkan)",
        fees: 3000,
        _id: "63f49b35782ee5319712ebb4",
      },
      {
        city: "Dagon Myothit (east)",
        fees: 3000,
        _id: "63f49b49782ee5319712ebe5",
      },
      { city: "Hlaingthaya", fees: 3000, _id: "63f49b69782ee5319712ec1a" },
      { city: "Insein", fees: 3000, _id: "63f49b77782ee5319712ec53" },
      { city: "Kamayut", fees: 3000, _id: "63f49b80782ee5319712ec90" },
      { city: "Mingalardon", fees: 3000, _id: "63f49b8c782ee5319712ecd1" },
      {
        city: "Dagon Myothit (north)",
        fees: 3000,
        _id: "63f49b9f782ee5319712ed16",
      },
      {
        city: "Dagon Myothit (south)",
        fees: 3000,
        _id: "63f49bab782ee5319712ed5f",
      },
      { city: "North Okkala", fees: 3000, _id: "63f49bc5782ee5319712edac" },
      { city: "South Okkala", fees: 3000, _id: "63f49bd5782ee5319712ee02" },
      { city: "Shwe Pyi Tha", fees: 3000, _id: "63f49bf4782ee5319712ee58" },
      { city: "Tarmwe", fees: 3000, _id: "63f49bfd782ee5319712eeb1" },
      { city: "Mayangone", fees: 3000, _id: "63f49c0f782ee5319712ef0e" },
      { city: "Kyimyindaing", fees: 3000, _id: "63f49c1b782ee5319712ef6f" },
      { city: "Hlaing", fees: 3000, _id: "63f49c48782ee5319712efd4" },
      { city: "Thanhlyin", fees: 3000, _id: "63f49c6f782ee5319712f057" },
      { city: "Sanchaung", fees: 3000, _id: "63f49c77782ee5319712f0c4" },
      { city: "Thingangyun", fees: 3000, _id: "63f49c84782ee5319712f135" },
      { city: "Thaketa", fees: 3000, _id: "63f49c94782ee5319712f1aa" },
      { city: "Dawbon", fees: 3000, _id: "63f49ca9782ee5319712f223" },
      { city: "Yankin", fees: 3000, _id: "63f49cb2782ee5319712f2a0" },
      { city: "Htauk Kyant", fees: 4000, _id: "63f49cd3782ee5319712f321" },
      { city: "Hmawbi", fees: 4000, _id: "63f49ce7782ee5319712f3a6" },
      { city: "Hlegu", fees: 4000, _id: "63f49cf9782ee5319712f42f" },
      { city: "Taik Kyi", fees: 4000, _id: "63f49d16782ee5319712f4bc" },
      { city: "Kyauktan", fees: 4000, _id: "63f49d22782ee5319712f54d" },
      {
        city: "Khayan Thone Khwa",
        fees: 4000,
        _id: "63f49d46782ee5319712f5e2",
      },
      { city: "Twantay", fees: 4000, _id: "63f49d61782ee5319712f67b" },
      { city: "Dala", fees: 4000, _id: "63f49d6b782ee5319712f718" },
    ],
    createdAt: "2023-02-21T09:21:10.924Z",
    updatedAt: "2023-02-21T10:31:07.218Z",
    __v: 0,
  },
];

export default data;
